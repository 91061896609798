// TODO: Use sizes from here
$xxsmall: 9px;
$xsmall: 10px;
$small: 12px;
$xmedium: 13px;
$medium: 14px;
$large: 16px;
$xlarge: 18px;
$xxlarge: 20px;
$xxxlarge: 23px;
$title: 26px;
$xtitle: 30px;
$large-title: 36px;
$icon-button-big: 40px;
$icon-button-medium: 25px;
$icon-buttom-small: 10px;
