$black: rgba(0, 0, 0, 0.87);
$white: #FFF;
$transparent: transparent;
$card-background: #FAFAFA;
$card-shadow-1: rgba(0, 0, 0, 0.2);
$card-shadow-2: rgba(0, 0, 0, 0.14);
$card-shadow-3: rgba(0, 0, 0, 0.12);
$card-shadow-4: rgba(0, 0, 0, 0.24);
$map: rgba(0, 0, 0, 0.54);
$skeleton-base: #CCC;
$skeleton-first: #DDD;
$skeleton-second: #E8E8E8;

$light-blue: #4D97FA;
$dark-blue: #1D558C;
$blue: #387AC9;
$disabled-gray: rgba(0, 0, 0, 0.38);
$gray: rgba(0, 0, 0, 0.54);
$gray2: #5E5E5E;
$gray-3: #D9D9D9;
$gray-4: #6D8D9D;
$button-pressed-gray: rgba(0, 0, 0, 0.2);
$error: #F44336;
$input-primary: #4D97FA;
$divider-base: rgba(0, 0, 0, 0.15);
$file-input-drag-glow: #9ECAED;
$file-input-loading-first: #DADADA;
$file-input-loading-second: #999;
$loading-base: #4D97FA;
$notification-success: #090;
$notification-error: #C00;
$notification-info: #0065A3;
$notification-warning: #FFA000;
$rating-star-selected: #FFD700;
$orange-icon: #EE6C27;
$switch-hover: rgba(1, 1, 1, 0.07);
$switch-active: rgba(1, 1, 1, 0.17);
$switch-gray: #CCC;
$switch-gray-hover: rgba(180, 180, 180, 1);
$image-radio-background: #E5E5E5;
$image-radio-background-hover: darken(#E5E5E5, 10%);

$error-red: #F44336;
$card-background: #FAFAFA;

// sass-lint:disable no-misspelled-properties
// sass-lint:disable property-sort-order
:export {
  black: $black;
  white: $white;
  transparent: $transparent;
  cardBackground: $card-background;
  cardShadow1: $card-shadow-1;
  cardShadow2: $card-shadow-2;
  cardShadow3: $card-shadow-3;
  cardShadow4: $card-shadow-4;
  map: $map;
  blue: $blue;
  darkBlue: $dark-blue;
  disabledGray: $disabled-gray;
  dividerBase: $divider-base;
  error: $error;
  gray: $gray;
  gray2: $gray2;
  gray3: $gray-3;
  gray4: $gray-4;
  inputPrimary: $input-primary;
  lightBlue: $light-blue;
  loadingBase: $loading-base;
  notificationError: $notification-error;
  notificationInfo: $notification-info;
  notificationSuccess: $notification-success;
  notificationWarning: $notification-warning;
  orangeIcon: $orange-icon;
  skeletonBase: $skeleton-base;
  switchHover: $switch-hover;
  switchActive: $switch-active;
  switchGray: $switch-gray;
  switchGrayHover: $switch-gray-hover;
  imageRadioBackground: $image-radio-background;
  imageRadioBackgroundHover: $image-radio-background-hover;
}
